body{
  background-image: url(images/sasf.png); 
  background-repeat: no-repeat;
  background-position: center left;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.errorMsg{
  color: tan; 
}

.successMsg{
  color: tan; 
}